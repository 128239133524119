import React from 'react'
import styled from 'styled-components'
import { Link, useRouteMatch } from 'react-router-dom'
import { Container, Item, space } from '@hank-technology/chocolates'
import { Box, Users } from 'icons'

export const sidebarWidth = '72px'

type LinkProps = {
  to: string
}

type FillableLinkProps = {
  shouldFill?: boolean
}

const LinkWrapper = styled(Item)<FillableLinkProps>`
  ${({ shouldFill }) => (shouldFill ? 'background-color: #E7E6E5;' : '')}
  width: ${space(5)};
  height: ${space(5)};
  border-radius: 8px;
`

const Wrapper = styled(Container)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${sidebarWidth};
  background-color: #fafafa;
  z-index: 1;
`

const ActiveLink: React.FC<LinkProps> = ({ children, to }) => {
  const match = useRouteMatch({
    path: to,
    exact: to === '/',
  })

  return (
    <Item center py={1.5}>
      <LinkWrapper shouldFill={!!match} center>
        <Link to={to}>{children}</Link>
      </LinkWrapper>
    </Item>
  )
}

const Sidebar = () => (
  <Wrapper direction="column" align="center" justify="flex-start" px={2} py={2}>
    <ActiveLink to="/users">
      <Users />
    </ActiveLink>
    <ActiveLink to="/orders">
      <Box />
    </ActiveLink>
  </Wrapper>
)

export default Sidebar
