import React from 'react'
import { Link, Text } from '@hank-technology/chocolates'

type ConditionalLinkProps = {
  href?: string | null
  text?: string | null
  target: string
}

const ConditionalLink = ({ href, text, target }: ConditionalLinkProps) => {
  if (!href || !text) return <Text>{'N/A'}</Text>

  return (
    <Link target={target} onClick={(e: any) => e.stopPropagation()} href={href}>
      {text}
    </Link>
  )
}

export default ConditionalLink
