import React from 'react'
import { Link, Text, User } from '@hank-technology/chocolates'

type UserLinkProps = {
  user: User
}

const UserLink = ({ user }: UserLinkProps) => {
  if (!user) return <Text>{'N/A'}</Text>

  return (
    <Link onClick={(e: any) => e.stopPropagation()} href={`/users/${user.id}`}>
      {user.slug}
    </Link>
  )
}

export default UserLink
