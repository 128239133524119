import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { DefaultThemeProvider, defaultTheme } from '@hank-technology/chocolates'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { apiBase, auth0 } from 'config'
import { UserPage, Users, LoginSuccess, Order } from 'pages'
import PageWrapper from 'components/PageWrapper'
import RedirectNonAdmins from 'components/RedirectNonAdmins'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Orders from 'pages/Orders'

const queryClient = new QueryClient()

const routes = [
  {
    path: '/login-success',
    component: LoginSuccess,
    open: true,
  },
  {
    path: '/users/:userId',
    component: UserPage,
  },
  {
    path: '/users',
    component: Users,
  },
  {
    path: '/orders/:orderId',
    component: Order,
  },
  {
    path: '/orders',
    component: Orders,
  },
]

function App() {
  return (
    <DefaultThemeProvider
      theme={{
        ...defaultTheme,
        typography: {
          ...defaultTheme.typography,
          headingType: 'SF Pro, sans serif',
          baseType: 'SF Pro, sans serif',
        },
      }}
      config={{
        apiBase,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Auth0Provider
          domain={auth0.domain}
          clientId={auth0.clientId}
          audience={auth0.audience}
          redirectUri={`${window.location.origin}/login-success`}
          cacheLocation="localstorage"
        >
          <Router>
            <PageWrapper>
              <Switch>
                {routes.map((r) =>
                  r.open ? (
                    <Route
                      path={r.path}
                      exact
                      component={r.component}
                      key={r.path}
                    />
                  ) : (
                    <Route path={r.path} exact key={r.path}>
                      <RedirectNonAdmins>
                        <r.component />
                      </RedirectNonAdmins>
                    </Route>
                  )
                )}
                <Route path="*">
                  <Redirect to="/users" />
                </Route>
              </Switch>
            </PageWrapper>
          </Router>
        </Auth0Provider>
      </QueryClientProvider>
    </DefaultThemeProvider>
  )
}

export default App
