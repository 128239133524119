import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import * as FullStory from '@fullstory/browser'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Container,
  Item,
  useCurrentUser,
  User,
} from '@hank-technology/chocolates'
import { User as UserIcon } from 'icons'

const AccountWrapper = styled(Container)`
  position: relative;
`

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
`

const Menu = styled(Container)`
  position: absolute;
  top: 44px;
  right: 64px;
  width: 240px;
  border-radius: 3px;
  z-index: 1;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 16px 24px rgba(41, 40, 39, 0.1);
`

const Divider = styled(Item)`
  border-bottom: 1px solid #dbdbdb;
  width: 100%;
`

const IdentifyUser = ({ user }: { user: User }) => {
  useEffect(() => {
    if (
      !user ||
      !user.id ||
      // @ts-ignore
      window._fullStory_identified
    )
      return

    FullStory.identify(user.id, {
      email: user.email || '',
      displayName: user.slug || '',
    })
    // @ts-ignore
    window._fullStory_identified = true
  }, [user])

  return null
}

const AccountMenu: React.FC = () => {
  const [isOpen, setOpen] = useState(false)
  const { isAuthenticated, logout } = useAuth0()
  const { data: user } = useCurrentUser()

  if (!isAuthenticated) return null
  if (!user) return null

  return (
    <Fragment>
      <IdentifyUser user={user} />
      <AccountWrapper py={2} px={8}>
        <div onClick={() => setOpen(!isOpen)} role="button">
          <UserIcon />
        </div>
        {isOpen ? (
          <>
            <Overlay onClick={() => setOpen(false)}></Overlay>
            <Menu direction="column">
              <Item py={1} px={1.5}>
                {user.name}
              </Item>
              <Divider py={0.5} />
              <Item
                py={1}
                px={1.5}
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                Logout
              </Item>
            </Menu>
          </>
        ) : null}
      </AccountWrapper>
    </Fragment>
  )
}

export default AccountMenu
