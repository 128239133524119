import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Button,
  colors,
  Container,
  Div,
  Item,
  H2,
  usePaginatedModel,
  User,
  Link,
  H4,
  Pill,
  PaginatedList,
  Order,
} from '@hank-technology/chocolates'
import { easyUrl } from 'config'
import EditUserModal from 'components/EditUserModal'
import Award from 'icons/award'
import PaginatedOrders from 'components/PaginatedOrders'
import DeleteUserModal from 'components/DeleteUserModal'

const UserPage = () => {
  const { userId } = useParams<any>()
  const [editing, setEditing] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const { data: { results: [user] = [] } = {}, invalidateData } =
    usePaginatedModel<User>('users', { id: userId })
  if (!user) return null

  return (
    <>
      {editing ? (
        <EditUserModal
          onComplete={() => {
            setEditing(false)
          }}
          reload={invalidateData}
          user={user}
        />
      ) : null}

      {deleting ? (
        <DeleteUserModal
          onComplete={() => {
            setDeleting(false)
            window.location.href = '/users/'
          }}
          onDismiss={() => setDeleting(false)}
          user={user}
        />
      ) : null}

      <Container gap={6} direction="column">
        <Container>
          <Item cols={12}>
            <H2>{user.slug}</H2>
            <Div py={1}>
              <H4>
                <Link href={`${easyUrl}/p/${user.id}`} target="_blank">
                  View Profile
                </Link>
              </H4>
            </Div>
            {user.is_suspended && (
              <Div py={1}>
                <Container>
                  <Pill pillColor="#FF0000" color="white">
                    Suspended
                  </Pill>
                </Container>
              </Div>
            )}
            <Div py={1}>First name: {user.first_name}</Div>
            <Div py={1}>Last name: {user.last_name}</Div>
            <Div py={1}>Email: {user.email}</Div>
            <Div py={1}>Phone: {user.phone ?? 'N/A'}</Div>
            <Div py={1}>Location: {user.formatted_location ?? 'N/A'}</Div>
            <Div py={1}>Bio: {user.bio ?? 'N/A'}</Div>
            <Div py={1}>
              Reason for joining: {user.reason_for_joining ?? 'N/A'}
            </Div>
            {user.is_ambassador && (
              <Container align="center" py={1} gap={0.5}>
                <Award scale={1} /> Hank Ambassador
              </Container>
            )}
            <Container pt={2}>
              <Button onClick={() => setEditing(true)}>Edit user</Button>
              <Div pl={1}>
                <Button
                  href={`${easyUrl}/?impersonate_id=${userId}`}
                  target="_blank"
                  color={colors.red500}
                >
                  Impersonate user
                </Button>
              </Div>
              <Div pl={1}>
                <Button onClick={() => setDeleting(true)} color={colors.red500}>
                  Delete user ⚠️
                </Button>
              </Div>
            </Container>
          </Item>
        </Container>
        <Container>
          <Item cols={12}>
            <PaginatedList<Order>
              filters={{ requester_id: user.id }}
              maintainPageQueryParams={false}
              path="orders"
            >
              <PaginatedOrders />
            </PaginatedList>
          </Item>
        </Container>
      </Container>
    </>
  )
}

export default UserPage
