import React, { useState } from 'react'
import {
  Button,
  Container,
  FormField,
  H2,
  useDebounce,
  PaginatedList,
  ModelPaginationFilters,
  User,
} from '@hank-technology/chocolates'
import PaginatedUsers from 'components/PaginatedUsers'
import EditUserModal from 'components/EditUserModal'

const Users = () => {
  const [creating, setCreating] = useState(false)
  const [filters, setFilters] = useState<ModelPaginationFilters<User>>({})

  // Use different ones so they don't cancel each other if a user is wicked fast
  const debouncedUpdateName = useDebounce(setFilters, 200)
  const debouncedUpdateEmail = useDebounce(setFilters, 200)

  return (
    <Container direction="column">
      {creating ? (
        <EditUserModal
          onComplete={() => {
            setCreating(false)
          }}
          creating={true}
          user={null}
        />
      ) : null}
      <Container justify="space-between" align="center" py={3}>
        <H2 weight={600}>Users</H2>
        <Button onClick={() => setCreating(true)}>+ Add user</Button>
      </Container>
      <Container wrap={false} align="center" py={2}>
        <FormField
          label="Name search"
          placeholder="Search by name"
          field="name"
          onChange={({ field, value }) =>
            debouncedUpdateName((filters) => ({
              ...filters,
              [field]: value ? value : null,
            }))
          }
          style={{ maxWidth: '540px' }}
          pr={2}
        />
        <FormField
          label="Email filter"
          field="email"
          placeholder="Filter by email (exact match)"
          onChange={({ field, value }) =>
            debouncedUpdateEmail((filters) => ({
              ...filters,
              [field]: value ? value : null,
            }))
          }
          style={{ maxWidth: '540px' }}
          pr={2}
        />
        <FormField
          label="Slug filter"
          field="slug"
          placeholder="Filter by slug (exact match)"
          onChange={({ field, value }) =>
            debouncedUpdateName((filters) => ({
              ...filters,
              [field]: value ? value : null,
            }))
          }
          style={{ maxWidth: '540px' }}
          pr={2}
        />
      </Container>
      <PaginatedList<User>
        filters={filters}
        maintainPageQueryParams={false}
        path="users"
      >
        <PaginatedUsers />
      </PaginatedList>
    </Container>
  )
}

export default Users
