import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Link,
  Table,
  usePaginationContext,
  User,
} from '@hank-technology/chocolates'
import { easyUrl } from 'config'

const PaginatedUsers: React.FC = (props) => {
  const history = useHistory()
  const { results: users } = usePaginationContext<User>()

  const cols = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (user: User) =>
          `${user.first_name} ${user.last_name ?? ''}`.trim(),
      },
      {
        Header: 'Slug',
        accessor: (user: User) => (
          <Link target="_blank" href={`${easyUrl}/p/${user.slug}`}>
            {user.slug}
          </Link>
        ),
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Phone',
        accessor: 'phone',
      },
    ],
    []
  )

  return (
    <Table
      cols={cols}
      data={users}
      onRowClick={(_, user) => history.push(`/users/${user.id}`)}
    />
  )
}

export default PaginatedUsers
