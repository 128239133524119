import React, { useMemo } from 'react'
import {
  Container,
  Item,
  Order,
  Table,
  Text,
} from '@hank-technology/chocolates'
import { shortDateTime } from 'util/time'
import ConditionalLink from 'components/ConditionalLink'
import Section from './Section'

type OrderPricingProps = {
  order: Order
}

const OrderPricing = ({ order }: OrderPricingProps) => {
  const cols = useMemo(
    () => [
      {
        Header: 'Description',
        accessor: 'body',
        width: 300,
      },
      {
        Header: 'QTY',
        accessor: (_: Order) => 1,
        width: 20,
        minWidth: 30,
      },
      {
        Header: 'Unit price',
        accessor: (o: Order) => (o.order_price ? `$${o.order_price}` : 'TBD'),
        width: 50,
        minWidth: 80,
      },
      {
        Header: 'Amount',
        accessor: (o: Order) => (o.order_price ? `$${o.order_price}` : 'TBD'),
        width: 40,
        minWidth: 80,
      },
    ],
    []
  )

  const paymentCols = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (o: Order) =>
          o.created_at ? shortDateTime(o.created_at as string) : '(pending)',
      },
      {
        Header: 'Amount',
        accessor: (o: Order) => (o.order_price ? `$${o.order_price}` : 'N/A'),
      },
      {
        id: 'stripe-link',
        Header: '',
        accessor: (o: Order) => (
          <ConditionalLink
            href={
              'https://dashboard.stripe.com/invoices/' + o.stripe_invoice_id
            }
            target="_blank"
            text="View in stripe"
          />
        ),
      },
    ],
    []
  )
  return (
    <>
      <Section title="Order summary">
        <Table cols={cols} data={[order]} defaultColumn={{ wrap: false }} />
        <Container justify="flex-end">
          <Item cols={5} mdCols={6} smCols={12} direction="row">
            <Item py={1} cols={6} align="flex-end" direction="column">
              <Text py={1} weight={700}>
                Total
              </Text>
            </Item>
            <Item py={1} cols={6} align="flex-end" direction="column">
              <Text py={1} weight={700}>
                {order.order_price ? `$${order.order_price}` : 'TBD'}
              </Text>
            </Item>
          </Item>
        </Container>
      </Section>
      <Section title="Payment">
        <Table
          cols={paymentCols}
          data={[order]}
          defaultColumn={{ wrap: false }}
        />
      </Section>
    </>
  )
}

export default OrderPricing
