export const capitalize = (str: string) => {
  return str[0].toUpperCase() + str.substring(1)
}

export const alterCareGroupHandle = (handle: string) => {
  if (/^Care group/.test(handle)) {
    return handle.replace(/Care group/i, 'View messages')
  }
  return handle
}
