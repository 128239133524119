import React, { useContext, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  ChocolatesContext,
  setSessionKey,
  useCurrentUser,
} from '@hank-technology/chocolates'

const RedirectOrRender: React.FC<{ user?: { admin: boolean } }> = ({
  children,
  user,
}) => {
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (!user || !user.admin) {
      setSessionKey({ name: 'login_redirect_url', value: window.location.href })
      loginWithRedirect({ prompt: 'login' })
    }
  }, [user, loginWithRedirect])

  if (!user || !user.admin) return null

  return <>{children}</>
}

const RedirectNonAdmins: React.FC = ({ children }) => {
  console.log('loaded redirect')
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0()
  const { data: user } = useCurrentUser()
  const { authToken, setConfig } = useContext(ChocolatesContext)

  useEffect(() => {
    if (isLoading) return
    if (!isAuthenticated) {
      setSessionKey({ name: 'login_redirect_url', value: window.location.href })
      loginWithRedirect({ prompt: 'login' })
    } else if (!authToken) {
      const getToken = async () => {
        const token = await getAccessTokenSilently()
        setConfig((config) => ({ ...config, authToken: token }))
      }
      getToken()
    }
  }, [
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    authToken,
    setConfig,
  ])

  console.log({ user })

  if (isLoading || !isAuthenticated || !user) return null

  return <RedirectOrRender user={user}>{children}</RedirectOrRender>
}

export default RedirectNonAdmins
