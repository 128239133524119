import { DateTime } from 'luxon'

type dateInput = DateTime | string | null | undefined

export const isSame = (
  unit: Parameters<DateTime['startOf']>[0],
  a: DateTime,
  b = DateTime.local()
) => {
  return a.startOf(unit).toMillis() === b.startOf(unit).toMillis()
}

export const isSameDay = (a: DateTime, b = DateTime.local()) => {
  return isSame('day', a, b)
}

export const fromISO = (iso: any) => DateTime.fromISO(iso)

export const dateStr = (luxon: DateTime) => luxon.toFormat('D')

export const shortDate = (luxon: DateTime) => {
  return luxon.toFormat('LLL d yyyy')
}

export const shortDateTime = (ts: dateInput) => {
  if (!ts) return ''
  const luxon: DateTime = typeof ts === 'string' ? fromISO(ts) : ts

  return `${shortDate(luxon)}, ${luxon.toFormat('t')}`
}

export const relativeDate = (ts: DateTime | string) => {
  if (!ts) return ''
  const luxon: DateTime = typeof ts === 'string' ? fromISO(ts) : ts

  const now = DateTime.local()

  if (isSameDay(luxon, now)) {
    return 'Today'
  }

  if (isSameDay(luxon, now.minus({ days: 1 }))) {
    return 'Yesterday'
  }

  return luxon.toFormat('D')
}

export const relativeTime = (ts: dateInput) => {
  if (!ts) return ''
  const luxon: DateTime = typeof ts === 'string' ? fromISO(ts) : ts
  return `${relativeDate(luxon)} at ${luxon.toFormat('t')}`
}
