import {
  Div,
  FieldEdit,
  FormField,
  H4,
  Item,
  Modal,
  P,
  User,
  colors,
  space,
  useRequest,
} from '@hank-technology/chocolates'
import { apiBase } from 'config'
import React, { useReducer, useState } from 'react'
import styled from 'styled-components'
import { capitalize } from 'util/strings'

export type UserModalProps = {
  creating?: boolean
  user: User | null
  onComplete: () => any
  reload?: () => any
  careGroupInfo?: {
    handle: string
    role: 'care_recipient' | 'caregiver'
    id: string
  }
}

const Section = styled(Item)`
  border-bottom: 1px solid ${colors.gray100};
  padding-bottom: ${space(4)};
  padding-top: ${space(4)};

  &:first-child {
    padding-top: 0;
  }
`

type FieldConfig = Partial<Parameters<typeof FormField>[0]> & {
  field: string
  accessor?: string
  disableIf?: (user: User | null | undefined) => boolean
}

type FieldGroup = {
  title: string
  fields?: FieldConfig[]
  address?: boolean
}

const fieldGroups = [
  {
    title: 'Profile information',
    fields: [
      {
        field: 'first_name',
      },
      {
        field: 'last_name',
      },
      {
        label: 'Auth0 ID',
        field: 'auth0_id',
      },
      {
        field: 'preferred_name',
      },
      {
        field: 'email',
        disableIf: (user: User | null | undefined) => user && user.auth0_id,
      },
      {
        field: 'phone',
        label: 'Phone number',
      },
    ],
  },
  {
    title: 'Add ons',
    fields: [
      {
        field: 'is_ambassador',
        label: 'User is a Hank Community Ambassador',
        inputType: 'checkbox',
      },
    ],
  },
  {
    title: 'Details',
    fields: [
      {
        field: 'notes',
        inputType: 'multiLineText',
      },
      {
        field: 'internal_notes',
        inputType: 'multiLineText',
      },
    ],
  },
  {
    title: 'Notifications',
    fields: [
      {
        field: 'receive_order_sms_updates',
        inputType: 'checkbox',
        label: 'Receives order SMS updates',
      },
      {
        field: 'pricing_sms',
        inputType: 'checkbox',
        label: 'Receives price notifications',
      },
      {
        field: 'receive_message_notifications',
        inputType: 'checkbox',
        label: 'Receives message notifications',
      },
      {
        field: 'receive_new_event_notifications',
        inputType: 'checkbox',
        label: 'Receives new event notifications',
      },
    ],
  },
  {
    title: 'Danger zone!',
    fields: [
      {
        field: 'is_suspended',
        inputType: 'checkbox',
        label: 'Suspend user',
      },
    ],
  },
] as FieldGroup[]

// @ts-ignore
const getUserValue = (user, accessor) => (user ? user[accessor] : null)

const UserModal = ({
  reload,
  onComplete,
  user,
  creating = false,
  careGroupInfo,
}: UserModalProps) => {
  const request = useRequest(apiBase)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [userEdits, updateField] = useReducer(
    (prevUserEdits: object, { field, value }: FieldEdit) => ({
      ...prevUserEdits,
      [field]: value,
    }),
    {
      ...(creating && {
        receive_order_sms_updates: true,
      }),
      ...(careGroupInfo && { care_group: careGroupInfo }),
    }
  )

  const onSubmit = async () => {
    if (!creating && !user) return
    setSubmitting(true)

    const { error, fieldErrors, body } = await request({
      // @ts-ignore
      path: creating ? 'users' : `v1/users/${user.id}`,
      method: creating ? 'post' : 'put',
      body: userEdits,
    })

    if (error) {
      console.error('Failed to create or update user', error, body)
    } else if (fieldErrors) {
      setErrors(fieldErrors)
    } else {
      setErrors({})
      setError(null)
      onComplete()
      reload && reload()
    }

    setSubmitting(false)
  }

  if (!creating && !user) return null

  let title = creating ? 'Add user' : 'Edit user'
  if (careGroupInfo)
    title = `Add ${careGroupInfo.role.replace('_', ' ')} to ${
      careGroupInfo.handle
    }`

  return (
    <Modal
      open={true}
      titleText={title}
      onCancel={onComplete}
      cancelText="Cancel"
      submitText="Submit"
      onSubmit={onSubmit}
      onDismiss={onComplete}
      submitDisabled={submitting}
    >
      <Div pb={2} px={1}>
        {fieldGroups.map((group) =>
          group.address && !user ? null : (
            <Section direction={'column'} key={group.title}>
              <H4 weight={600}>{group.title}</H4>
              {group.fields &&
                group.fields.map(
                  ({ field, accessor, label, disableIf, ...rest }) => (
                    <FormField
                      pt={2}
                      {...rest}
                      key={field}
                      field={field}
                      error={errors[field]}
                      label={label || capitalize(field.replace('_', ' '))}
                      value={
                        getUserValue(user, accessor || field) ||
                        userEdits[field]
                      }
                      onChange={updateField}
                    />
                  )
                )}
            </Section>
          )
        )}
      </Div>
      {error && <P error>{error}</P>}
    </Modal>
  )
}

export default UserModal
