import React, { useEffect, useState } from 'react'
import {
  H2,
  Tabs,
  Container,
  ModelPaginationFilters,
  Order,
  usePaginationContext,
} from '@hank-technology/chocolates'

type ListPageTab = {
  label: string
  PaginationComponent: any
  filters: ModelPaginationFilters<Order>
}

type ListPageProps = {
  tabs: ListPageTab[]
  title: string
}

const ListPage = ({ tabs, title }: ListPageProps) => {
  const [tab, setTab] = useState<number>(0)
  const { setFilters } = usePaginationContext<Order>()

  const { PaginationComponent, filters } = tabs[tab] ?? {}

  useEffect(() => {
    setFilters(filters)
  }, [filters, setFilters])

  return (
    <Container direction="column">
      <H2 pb={3} weight={600}>
        {title}
      </H2>
      <Container gap={4}>
        <Tabs
          activeTab={tab}
          setActiveTab={setTab}
          tabs={tabs.map((tab) => ({ label: tab.label }))}
        />
        <PaginationComponent />
      </Container>
    </Container>
  )
}

export default ListPage
