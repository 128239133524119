import React from 'react'
import ListPage from 'components/ListPage'
import PaginatedOrders from 'components/PaginatedOrders'
import { DateTime } from 'luxon'
import {
  Container,
  PaginatedList,
  PAGINATION_DIRECTION,
} from '@hank-technology/chocolates'

const defaultTab = {
  queryVars: {},
  PaginationComponent: PaginatedOrders,
}

const Orders = () => (
  <PaginatedList maintainPageQueryParams={false} path="orders">
    <Container direction="column">
      <ListPage
        title="Orders"
        tabs={[
          {
            ...defaultTab,
            label: 'Upcoming fulfilled orders',
            filters: {
              'scheduled_at[gt]': DateTime.local().minus({ days: 1 }).toISO(),
              status: 'scheduled',
              order_by: 'scheduled_at',
              direction: PAGINATION_DIRECTION.DESC,
            },
          },
          {
            ...defaultTab,
            label: 'Unfulfilled',
            filters: {
              status: 'requested',
              order_by: 'created_at',
              direction: PAGINATION_DIRECTION.ASC,
            },
          },
          {
            ...defaultTab,
            label: 'Fulfilled',
            filters: {
              status: 'scheduled',
              order_by: 'scheduled_at',
              direction: PAGINATION_DIRECTION.DESC,
            },
          },
          {
            ...defaultTab,
            label: 'Paid',
            filters: {
              status: 'charged',
              order_by: 'scheduled_at',
              direction: PAGINATION_DIRECTION.DESC,
            },
          },
          {
            ...defaultTab,
            label: 'Cancelled',
            filters: {
              status: 'canceled',
              order_by: 'scheduled_at',
              direction: PAGINATION_DIRECTION.DESC,
            },
          },
          {
            ...defaultTab,
            label: 'All',
            filters: {
              order_by: 'created_at',
              direction: PAGINATION_DIRECTION.DESC,
            },
          },
        ]}
      />
    </Container>
  </PaginatedList>
)

export default Orders
