import {
  Div,
  FormField,
  Modal,
  P,
  useRequest,
} from '@hank-technology/chocolates'
import { apiBase } from 'config'
import React, { useState } from 'react'
import { UserModalProps } from './EditUserModal'

const DeleteUserModal = ({
  user,
  onComplete,
  onDismiss,
}: UserModalProps & { onDismiss: () => any }) => {
  const request = useRequest(apiBase)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [inputEmail, setInputEmail] = useState('')
  if (!user) return null

  const onSubmit = async () => {
    if (inputEmail !== user.email) {
      setErrors({ email: 'Email does not match' })
      return
    }

    setSubmitting(true)
    const { error, body } = await request({
      path: `v1/users/${user.id}`,
      method: 'delete',
    })

    if (error) {
      console.error('Failed to delete user', error, body)
    } else {
      setErrors({})
      setError(null)
      onComplete()
    }

    setSubmitting(false)
  }

  return (
    <Modal
      open={true}
      titleText={'Delete user'}
      onCancel={onDismiss}
      cancelText="Cancel"
      submitText="Delete ⚠️"
      onSubmit={onSubmit}
      onDismiss={onDismiss}
      submitDisabled={submitting}
    >
      <Div pb={2} px={1}>
        <P>
          Confirm user email to delete: <strong>{user?.email}</strong>
        </P>
        <FormField
          pt={2}
          field="email"
          inputType="text"
          error={errors.email}
          onChange={(edit) => {
            setInputEmail(edit.value)
          }}
        />
      </Div>
      {error && <P error>{error}</P>}
    </Modal>
  )
}

export default DeleteUserModal
