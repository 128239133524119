import React, { useContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Redirect } from 'react-router-dom'
import { apiBase } from 'config'
import {
  ChocolatesContext,
  clearSessionKey,
  getSessionKey,
  request,
} from '@hank-technology/chocolates'

const LoginSuccess = () => {
  const { setConfig, authToken } = useContext(ChocolatesContext)
  const { user, loginWithRedirect, getAccessTokenSilently } = useAuth0()
  const [done, setDone] = useState(false)

  useEffect(() => {
    if (authToken) return

    const getToken = async () => {
      const token = await getAccessTokenSilently()
      setConfig((config) => ({ ...config, authToken: token }))
    }
    getToken()
  })

  useEffect(() => {
    if (done || !authToken) return

    const notifyLoginSuccess = async () => {
      const { error, body } = await request({
        auth0Token: authToken,
        apiBase,
        method: 'post',
        path: 'logins',
        body: {
          email: user.email,
          email_verified: user.email_verified,
        },
      })

      if (error) {
        console.error('Failed to log in:', error, body)
        loginWithRedirect({
          prompt: 'login',
        })
      } else {
        const redirectUrl = getSessionKey('login_redirect_url')
        if (redirectUrl) {
          clearSessionKey('login_redirect_url')
          window.location.href = redirectUrl
        } else {
          setDone(true)
        }
      }
    }

    notifyLoginSuccess()
  }, [
    user,
    setDone,
    done,
    loginWithRedirect,
    getAccessTokenSilently,
    setConfig,
    authToken,
  ])

  if (!done) return null

  return <Redirect to="/" />
}

export default LoginSuccess
