import React from 'react'
import styled from 'styled-components'
import { Container, LayoutWrapper } from '@hank-technology/chocolates'
import AccountMenu from 'components/AccountMenu'
import Sidebar, { sidebarWidth } from 'components/Sidebar'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Rest = styled.div`
  padding-left: ${sidebarWidth};
  position: relative;
`

export const headerHeight = '60px'

const Header = styled(Container)`
  position: sticky;
  top: 0;
  background-color: #ffffff;
  height: ${headerHeight};
  justify-content: flex-end;
  box-shadow: inset 0px -1px 0px #e7e6e5;
`
const Body = styled(LayoutWrapper)`
  width: 100%;
`

const PageWrapper: React.FC = ({ children }) => (
  <Wrapper>
    <Sidebar />
    <Rest>
      <Header>
        <AccountMenu />
      </Header>
      <Body py={2}>{children}</Body>
    </Rest>
  </Wrapper>
)

export default PageWrapper
