import React from 'react'
import { useParams } from 'react-router-dom'
import { useOrder } from '@hank-technology/chocolates'
import OrderHeader from './Order/OrderHeader'
import OrderDetails from './Order/OrderDetails'
import OrderUserDetails from './Order/OrderUserDetails'
import OrderPricing from './Order/OrderPricing'

const Order = () => {
  const { orderId } = useParams<any>()
  const { order, mutate } = useOrder(orderId)

  if (!order) return null

  return (
    <>
      <OrderHeader reload={mutate} order={order} />
      <OrderDetails order={order} />
      <OrderUserDetails order={order} />
      <OrderPricing order={order} />
    </>
  )
}

export default Order
