const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
export const isProd = process.env.NODE_ENV === 'production'

export const apiBase = isDev
  ? 'http://localhost:3000'
  : process.env.REACT_APP_API_BASE
export const easyUrl = isDev
  ? 'http://localhost:5001'
  : process.env.REACT_APP_EASY_APP_URL

export const auth0 = {
  domain: isDev
    ? 'dev.login.gethank.com'
    : process.env.REACT_APP_WILSON_AUTH0_DOMAIN,
  clientId: isDev
    ? 'PIDn2yxP1dvGh3ygvzY1WfXI09J5ThQw'
    : process.env.REACT_APP_WILSON_AUTH0_CLIENT_ID,
  audience: apiBase,
}

export const sentryConfig = {
  environment:
    process.env.VERCEL_ENV || process.env.REACT_APP_VERCEL_ENV || 'local',
  dsn:
    process.env.REACT_APP_SENTRY_DSN ||
    'https://822c76c066284f2eb766be90ff827b96@o494554.ingest.sentry.io/5574065',
  release: process.env.REACT_APP_SENTRY_RELEASE,
}

export const fullStoryOrgId = 'YR2WH'
