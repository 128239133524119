import React from 'react'
import { Link, Order, User } from '@hank-technology/chocolates'
import Section from './Section'
import UserLink from 'components/UserLink'

type OrderUserDetailsProps = {
  order: Order
}

const OrderUserDetails = ({ order }: OrderUserDetailsProps) => {
  let sections = []
  const { recipient } = order

  const userFields = (u: User) => [
    [
      {
        key: 'User',
        value: <UserLink user={u} />,
      },
      {
        key: 'Contact number',
        value: (u.phone as string) || 'N/A',
      },
    ],
    [
      {
        key: 'Email',
        value: (u.email as string) || 'N/A',
      },
      {
        key: 'Payment configuration',
        value: u.stripe_url ? (
          <Link href={u.stripe_url}>View in Stripe</Link>
        ) : (
          'None'
        ),
      },
    ],
  ]

  if (recipient)
    sections.push({
      title: 'Recipient',
      groups: userFields(recipient),
    })

  if (order.payer)
    sections.push({
      title: 'Payer',
      groups: userFields(order.payer),
    })

  if (order.requester)
    sections.push({
      title: 'Requester',
      groups: userFields(order.requester),
    })

  return (
    <>
      {sections.map((g) => (
        <Section key={g.title} title={g.title} groups={g.groups} />
      ))}
    </>
  )
}

export default OrderUserDetails
