import React from 'react'
import styled from 'styled-components'
import {
  colors,
  Container,
  Div,
  H4,
  Item,
  Text,
} from '@hank-technology/chocolates'

type Field = {
  key: string
  value: React.ReactNode
}
type Group = Field[]
type SectionProps = {
  title: string
  groups?: Group[]
}

const Contents = styled(Div)`
  border-top: 1px solid ${colors.gray100};
`

const Section: React.FC<SectionProps> = ({ title, groups, children }) => (
  <>
    <H4 pt={4} pb={1} weight={600}>
      {title}
    </H4>
    <Contents pt={2} pb={4}>
      {groups ? (
        <Container direction="row">
          {groups.map((group, idx) => (
            <Item key={`section-${idx}`} cols={6} smCols={12} direction="row">
              <Item cols={5} direction="column">
                {group.map((field, fieldIdx) => (
                  <Text key={`field-${fieldIdx}`} color={colors.gray600} py={1}>
                    {field.key}
                  </Text>
                ))}
              </Item>
              <Item cols={7} direction="column">
                {group.map((field, fieldIdx) => (
                  <Text key={`field-${fieldIdx}`} py={1}>
                    {field.value}
                  </Text>
                ))}
              </Item>
            </Item>
          ))}
        </Container>
      ) : null}
      {children}
    </Contents>
  </>
)

export default Section
