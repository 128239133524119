import React from 'react'
import { Pill, colors } from '@hank-technology/chocolates'

type OrderStatusProps = {
  status: string
}

const colorMap: { [key: string]: string } = {
  requested: colors.blue100,
  scheduled: colors.gray100,
  canceled: colors.red200,
}

const OrderStatus = ({ status }: OrderStatusProps) => (
  <div style={{ width: 100 }}>
    <Pill
      style={{ textTransform: 'capitalize', textAlign: 'center' }}
      pillColor={colorMap[status] || colors.gray100}
    >
      {status === 'scheduled' ? 'fulfilled' : status}
    </Pill>
  </div>
)

export default OrderStatus
