import React from 'react'

const width = 20
const height = 20

const Award = ({ scale = 1 }: { scale: number }) => (
  <svg
    width={scale * height}
    height={scale * width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_13815_92281)">
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="#8B4FE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7538 2.63197C13.2517 1.95871 14.3071 2.15892 14.5238 2.96773L16.6841 11.0302C16.9008 11.839 16.087 12.5401 15.3192 12.2059L12.2848 10.8855C12.0774 10.7952 11.8455 10.778 11.627 10.8365L5.99837 12.3447C5.46491 12.4876 4.91657 12.171 4.77363 11.6376L3.90531 8.39696C3.76236 7.86349 4.07895 7.31515 4.61241 7.17221L10.241 5.66403C10.4595 5.60549 10.6517 5.47458 10.7862 5.29269L12.7538 2.63197ZM6.2758 13.3791C5.74233 13.522 5.42575 14.0704 5.56869 14.6038L6.34461 17.4996C6.48756 18.0331 7.03589 18.3497 7.56936 18.2067L9.66054 17.6464C10.194 17.5035 10.5106 16.9551 10.3676 16.4217L9.59172 13.5259C9.44878 12.9924 8.90044 12.6758 8.36698 12.8188L6.2758 13.3791Z"
        fill="#F6C042"
      />
    </g>
    <defs>
      <clipPath id="clip0_13815_92281">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Award
