import React from 'react'
import { H4, H5, Container, Item, P, Order } from '@hank-technology/chocolates'
import OrderStatus from 'components/OrderStatus'
import OrderCancelForm from './OrderCancelForm'
import OrderScheduledForm from './OrderScheduledForm'

type OrderHeaderProps = {
  order: Order
  reload: () => any
}

const OrderHeader = ({ order, reload }: OrderHeaderProps) => {
  return (
    <>
      <Container direction="column" gap={2} justify="space-between" grow>
        <Container direction="column">
          <H5 style={{ textTransform: 'uppercase' }}>Event</H5>
          <Item>
            <OrderStatus status={order.status || ''} />
          </Item>
        </Container>
        <Container direction="row" gap={2}>
          {order.id && (
            <div>
              <OrderScheduledForm
                status={order.status as string}
                orderId={order.id}
                done={reload}
              />
            </div>
          )}
          {order.status === 'canceled' ? (
            <div>
              <H4 weight={700}>This order is canceled.</H4>
              <P>Notes: {order.canceled_notes}</P>
            </div>
          ) : (
            <OrderCancelForm reload={reload} order={order} />
          )}
        </Container>
      </Container>
    </>
  )
}

export default OrderHeader
