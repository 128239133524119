import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Order, Table, usePaginationContext } from '@hank-technology/chocolates'
import { shortDateTime } from 'util/time'
import OrderStatus from './OrderStatus'
import UserLink from './UserLink'

const PaginatedOrders: React.FC = () => {
  const { results: orders = [] } = usePaginationContext<Order>()
  const history = useHistory()
  const cols = useMemo(
    () => [
      {
        Header: 'Due date',
        accessor: (order: Order) =>
          shortDateTime(order.scheduled_at) ||
          shortDateTime(order.preferred_dt),
      },
      {
        Header: 'Type',
        accessor: 'task_type',
      },
      {
        Header: 'Requestor',
        accessor: (order: any) => <UserLink user={order.requester} />,
      },
      {
        Header: 'Status',
        accessor: (order: Order) => <OrderStatus status={order.status} />,
        width: 30,
      },
      {
        Header: 'Requested',
        accessor: (order: Order) => shortDateTime(order.created_at),
      },
      {
        Header: 'Scheduled',
        accessor: (order: Order) => shortDateTime(order.scheduled_at),
      },
    ],
    []
  )

  return (
    <Table
      cols={cols}
      data={orders}
      defaultColumn={{ wrap: false, width: 50 }}
      onRowClick={(_, order) => history.push(`/orders/${order.id}`)}
    />
  )
}

export default PaginatedOrders
