import React, { useState } from 'react'
import {
  Button,
  colors,
  Div,
  FormField,
  Modal,
  Notice,
  Order,
  P,
  useRequest,
} from '@hank-technology/chocolates'
import { apiBase } from 'config'

const OrderCancelForm = ({
  order,
  reload,
}: {
  order: Order
  reload?: () => null
}) => {
  const [canceling, setCanceling] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [reason, setReason] = useState('')
  const request = useRequest(apiBase)

  const cancelOrder = async () => {
    const { error } = await request({
      path: `orders/${order.id}/cancel`,
      method: 'post',
      body: { reason },
    })

    setCanceling(false)
    if (error) {
      setError(error.message)
    } else {
      reload && reload()
    }
  }

  return (
    <Div>
      <Modal
        open={canceling}
        onCancel={() => setCanceling(false)}
        onSubmit={() => cancelOrder()}
        titleText="Cancel this order?"
        size="small"
        submitText="Cancel order"
        cancelText="Nevermind"
        submitColor={colors.red600}
      >
        <P pb={1}>Are you sure you want to cancel this order?</P>
        {order.status === 'charged' && order.stripe_invoice_id && (
          <Notice severity="warn" pb={1}>
            This order has already been charged. You must manually process a
            refund for this order in{' '}
            <a href={order.stripe_invoice_id}>the Stripe Dashboard</a>.
          </Notice>
        )}
        <FormField
          inputType="multiLineText"
          label="Cancellation reason"
          field="notes"
          onChange={({ value }) => setReason(value)}
        />
        {error && <Notice severity="error">{error}</Notice>}
      </Modal>
      <Button
        disabled={canceling || order.status === 'canceled'}
        secondary
        onClick={() => setCanceling(true)}
      >
        Cancel this order
      </Button>
    </Div>
  )
}

export default OrderCancelForm
