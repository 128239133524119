import React, { useState } from 'react'
import {
  Button,
  Div,
  FormField,
  Modal,
  Notice,
  useRequest,
} from '@hank-technology/chocolates'
import { apiBase } from 'config'

const OrderScheduledForm = ({
  done,
  orderId,
  status,
}: {
  done: () => void
  orderId: string
  status: string
}) => {
  const request = useRequest(apiBase)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [notes, setNotes] = useState('')
  const [open, setOpen] = useState(false)

  const markFailed = async () => {
    try {
      setSubmitting(true)
      const { error } = await request({
        path: `orders/${orderId}/schedule`,
        method: 'post',
        body: { notes },
      })

      setSubmitting(false)

      if (error) {
        setError(error.message)
      } else {
        done()
      }
    } finally {
      setOpen(false)
    }
  }

  return (
    <Div>
      <Button
        disabled={status === 'scheduled'}
        style={{ width: '100%' }}
        onClick={() => setOpen(true)}
      >
        Mark as fulfilled
      </Button>
      <Modal
        open={open}
        titleText="Mark as fulfilled"
        size="small"
        submitText="Mark as fulfilled"
        submitDisabled={submitting}
        cancelText="Cancel"
        onCancel={() => setOpen(false)}
        onSubmit={() => markFailed()}
      >
        <FormField
          inputType="multiLineText"
          label="Notes"
          field="notes"
          onChange={({ value }) => setNotes(value)}
        />
        {error && <Notice severity="error">{error}</Notice>}
      </Modal>
    </Div>
  )
}

export default OrderScheduledForm
