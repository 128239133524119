import React from 'react'
import { Order } from '@hank-technology/chocolates'
import { shortDateTime } from 'util/time'
import Section from './Section'

type OrderDetailsProps = {
  order: Order
}

const OrderDetails = ({ order }: OrderDetailsProps) => {
  return (
    <Section
      title="Request details"
      groups={[
        [
          {
            key: 'Vendor',
            value: (order.vendor as string) || 'N/A',
          },
          {
            key: 'Details',
            value: (order.body as string) || 'N/A',
          },
        ],
        [
          {
            key: "We've scheduled for",
            value: shortDateTime(order.scheduled_at as string) || 'N/A',
          },
          {
            key: 'User requested for',
            value: shortDateTime(order.preferred_dt as string),
          },
          {
            key: 'User requested at',
            value: shortDateTime(order.created_at as string),
          },
        ],
      ]}
    />
  )
}

export default OrderDetails
