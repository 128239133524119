import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { fullStoryOrgId, isProd, sentryConfig } from './config'
import './index.css'
import App from './App'
import * as FullStory from '@fullstory/browser'

if (isProd) {
  Sentry.init(sentryConfig)
}

FullStory.init({ orgId: fullStoryOrgId, devMode: !isProd })

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
